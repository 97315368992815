.ant-table-thead .ant-table-cell {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-grey-002);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 11px 0 11px 7px;
}
.ant-table-tbody > tr > td {
  border-color: var(--light-grey-005);
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: var(--light-blue-001);
}
.ant-table-cell {
  color: var(--dark-grey-004);
}
.ant-table-tbody a:hover,
.ant-table-tbody a:active {
  color: inherit;
}
.custom-table {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-grey-004);
  width: 100%;
}
.grey-row {
  background-color: var(--light-grey-003);
}
.custom-table td.ant-table-column-sort {
  background-color: initial;
}
.entity-table .ant-btn.entity-table__save-btn {
  width: 190px;
  position: absolute;
  top: 56px;
  right: 0;
}
.custom-table td.ant-table-column-sort {
  background-color: initial;
}
.custom-table--min-height {
  min-height: 220px;
}