.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
  background: var(--light-grey-004);
  color: var(--white);
}

/* Icon */
.button--icon {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
.button--icon:disabled {
  cursor: default;
}

/* Primary */
.button--primary  {
  background: var(--dark-grey-002);
  color: var(--white);
  border: none;
}
.button--primary:hover,
.button--primary:active,
.button--primary:focus {
  background: var(--dark-grey-001);
  color: var(--white);
}

/* Default */
.button--default, .button--default:hover,
.button--default:active,
.button--default:focus  {
  background: var(--blue-004);
  color: var(--white);
  border: none;
}

.button--default:hover,
.button--default:active,
.button--default:focus {
  background: var(--blue-002);
}

/* Danger */
.button--danger {
  background-color: var(--red-001);
  color: var(--white);
  border: none;
  border-radius: 3%;
}

.button--danger:hover,
.button--danger:active,
.button--danger:focus {
  background: var(--red-002);
  outline: none;
  color: var(--white);
  border: none;
}

/* Ghost */
.button--ghost:hover,
.button--ghost:active,
.button--ghost:focus {
  filter: brightness(50%);
}
/* Ghost black */
.button--ghost--black, .button--ghost--black:hover,
.button--ghost--black:active, .button--ghost--black:focus {
  color: var(--black);
  border: 1px solid var(--black);
}
/* Ghost green */
.button--ghost--green, .button--ghost--green:hover,
.button--ghost--green:active, .button--ghost--green:focus  {
  color: var(--light-green-002);
  border: 1px solid var(--light-green-002);
}

/* Ghost gray */
.button--ghost--gray,
.button--ghost--gray:active,
.button--ghost--gray:focus {
  color: var(--dark-grey-001);
  border: 1px solid var(--light-grey-004);
}

.button--ghost--gray:hover {
  opacity: 0.6;
}
