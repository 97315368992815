.ant-layout .pagination-wrapper,
.pagination-wrapper {
  display: flex;
  justify-content: center;
  padding: 48px 0 40px;
}
.pagination-wrapper .ant-pagination-item .ant-pagination-item-active a {
  color: var(--white);
}
.pagination-wrapper .ant-pagination-item {
  border: 1px solid var(--light-grey-002);
  background: var(--light-grey-005);
}
.ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: var(--white)
}
.pagination-wrapper .ant-pagination-item-active {
  background: #262626;
  border: none;
}
.ant-pagination-item > a {
  color: var(--white);
}
.ant-pagination-options {
  display: none;
}