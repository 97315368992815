.custom-tooltip .ant-tooltip {
  max-width: 265px;
}
.custom-tooltip .ant-tooltip-inner {
  background-color: var(--white);
  color: var(--dark-grey-002);
  padding: 6px 8px;
}
.custom-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background-color: var(--white);
}
.custom-tooltip .ant-tooltip-inner ul p {
  color: var(--light-grey-001);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 10px;
}
.custom-tooltip .ant-tooltip-inner ul {
  margin: 5px 8px 3px 8px;
  padding: 0;
}
.custom-tooltip .ant-tooltip-inner ul li {
  margin-left: 15px;
  padding: 1px 0;
}
.custom-tooltip .ant-tooltip-inner ul li::before {
  color: var(--black);
  width: 5px;
}