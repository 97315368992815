.ant-layout-header {
  background-color: var(--light-grey-003);
}
.ant-layout {
  background-color: var(--white);
}
.page-wrapper {
  margin: 40px;
  margin-right: 25px;
}
.page-title__holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 32px;
  margin-top: 56px;
  margin-bottom: 10px;
}

.page-title__holder h1 {
  font-size: 24px;
  line-height: 32px;
}
.full-width {
  width: 100%
}
.empty-list--min-height {
  min-height: 220px;
}