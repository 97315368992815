.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}
.ant-tabs {
  color: var(--dark-grey-004);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--black);
}
.ant-tabs-tab:hover,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: var(--dark-grey-003);
}