:root {
  --dark-grey-001: #333;
  --dark-grey-002: #262626;
  --dark-grey-003: #666666;
  --dark-grey-004: #595959;
  --light-grey-001: #8c8c8c;
  --light-grey-002: #d9d9d9;
  --light-grey-003: #f5f5f5;
  --light-grey-004: #bfbfbf;
  --light-grey-005: #e8e8e8;
  --light-grey-006: #fafafa;
  --light-blue-001: #e6f7ff;
  --pink-grey-001: #FFF2E8;
  --white: #fff;
  --black: #000;
  --red-001: #f5222d;
  --red-002: #cf1322;
  --orange-001: #FA541C;
  --yellow: #faad14;
  --green: #52c41a;
  --light-green-001: #24B685;
  --light-green-002: #7CB305;
  --light-green-003: #389E0D;
  --light-green-004: #F6FFED;
  --blue-001: #096dd9;
  --blue-002: #40a9ff;
  --blue-003: #69C0FF;
  --blue-004: #1890FF;
}
