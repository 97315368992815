.custom-datepicker {
  outline: none;
  width: 256px;
  margin-right: 12px;
}
.custom-datepicker .react-daterange-picker__wrapper {
  border-color: var(--light-grey-002);
  height: 30px;
}
.custom-datepicker .react-daterange-picker__wrapper > div {
  margin-left: 10px;
}
.custom-datepicker .react-daterange-picker__calendar {
  width: 256px;
}
.custom-datepicker .react-calendar__navigation {
  margin-bottom: 0;
}
.custom-datepicker .react-calendar__month-view__weekdays {
  display: none !important;
}
.custom-datepicker .react-calendar__navigation button {
  min-width: 34px;
}
.custom-datepicker .react-calendar__tile--active {
  background: var(--blue-002);
}
.custom-datepicker .react-calendar__tile--now,
.custom-datepicker .react-calendar__tile--now:hover {
  background: var(--light-blue-001);
}
.custom-datepicker .react-daterange-picker__inputGroup__input:invalid {
  background: none;
}
.custom-datepicker .react-calendar__month-view__days__day--weekend {
  color: inherit;
}
.custom-datepicker
  .react-calendar__tile--active.react-calendar__month-view__days__day--weekend {
  color: var(--white);
}
.custom-datepicker .react-daterange-picker__inputGroup__input {
  margin: 0 1px;
  outline: none;
}
.custom-datepicker
  .react-daterange-picker__clear-button.react-daterange-picker__button,
.custom-datepicker
  .react-daterange-picker__calendar-button.react-daterange-picker__button {
  outline: none;
}
.custom-datepicker .react-daterange-picker__calendar .react-calendar {
  border-top: none;
}
.custom-datepicker .react-daterange-picker__range-divider {
  padding-right: 5px;
}
.custom-datepicker.has-init-value .react-daterange-picker__range-divider {
  padding-left: 10px;
}
