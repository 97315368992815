.user-edit-watch-form__error {
  margin-top: -24px;
}

.watch-form__valuation .user-edit-watch-form__error {
  margin-top: 0;
  margin-bottom: 0px;
}
.user-edit-watch-form__band-type>.ant-radio-group {
  margin-bottom: 10px;
}

.user-edit-watch-form__error-validation {
  margin-bottom: 0;
}