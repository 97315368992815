@font-face {
  font-family: 'Rockwell';
  src: url('/assets/fonts/rockwell-regular') format('ttf');
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('/assets/fonts/sf-pro-display-regular') format('ttf');
}

@font-face {
  font-family: 'Shanti';
  src: url('/assets/fonts/shanti-regular') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/roboto') format('ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat') format('ttf');
}
