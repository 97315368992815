.ant-modal-wrap .ant-modal {
  width: 423px;
}
.ant-modal-wrap .ant-btn {
  color: var(--dark-grey-004);
}
.ant-modal-wrap .ant-btn-primary {
  color: var(--white);
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 600;
}