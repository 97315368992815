@import '~antd/dist/antd.css';
@import './styles/fonts.css';
@import './styles/variables.css';
@import './styles/common/index.css';

html {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: 'SF-Pro-Display', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-grey-002);
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  height: 100%;
}