.ant-notification {
  left: 50%;
  transform: translateX(-50%)
}

.ant-notification-hook-holder, .ant-notification-notice {
  width: 100%;
  margin: auto;
}

@media (min-width: 600px) {
  .ant-notification-hook-holder, .ant-notification-notice {
    width: 424px;
  }
}
@media (max-width: 700px) {
  .ant-notification {
    left: 0;
    transform: none;
    margin: 0 auto;
  }
}
