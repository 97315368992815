.login-form__title {
  font-size: 24px;
  line-height: 32px;
  color: var(--light-grey-001);
  padding-bottom: 42px;
  font-weight: lighter;
}
.login-form__description {
  font-family: 'Roboto', sans-serif;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--dark-grey-003);
  max-width: 367px;
}
.login-form__label label {
  color: var(--dark-grey-002);
  margin: 0px 2px;
}
.ant-input.login-form__input,
.ant-form-item-control-input .login-form__input {
  width: 300px;
  background: var(--white);
  border: 1px solid var(--light-grey-002);
  border-radius: 2px;
  padding: 7px 12px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'SF-Pro-Display', sans-serif;
}
.login-form__additional {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.login-form__login-type {
  line-height: 24px;
  color: var(--light-grey-001);
}
.login-form__forgot-password > a {
  line-height: 24px;
  color: var(--dark-grey-002);
}
.login-form__forgot-password > a:hover,
.login-form__forgot-password > a:active,
.login-form__forgot-password > a:focus {
  color: var(--dark-grey-003);
}
.login-form__social-icons {
  display: flex;
  justify-content: space-between;
  max-width: 140px;
  margin-top: 20px;
  margin-bottom: 75px;
}
.login-form .login-form__submit-btn {
  width: 327px;
  height: 48px;
  background: var(--dark-grey-002);
  border-radius: 4px;
  margin: 14px auto;
  font-family: 'Shanti', sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: var(--white);
  border: none;
  cursor: pointer;
}

.ant-row.ant-form-item.login-form__label {
  margin-bottom: 0;
}

.ant-form-item-label > label {
  height: 22px;
  padding-top: 4px;
}


#login .ant-form-item-label label::after {
  display: none;
}