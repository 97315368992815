.spinner {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
.spinner-height-auto {
  height: auto;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}