.carousel-root {
  max-width: 700px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1;
}
.carousel .slide {
  justify-content: center;
  display: flex;
}
.carousel .slide video {
  margin-left: 10px;
  outline: none;
}