.top-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  flex-flow: wrap;
  width: 100%;
}
.search-bar-holder {
  max-width: 394px;
  min-width: 256px;
  width: -webkit-fill-available;
  margin-top: 17px;
}
.filter-bar__filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.filter-bar__filters .ant-picker {
  height: 40px;
}
.filter-bar__filters .ant-checkbox-wrapper {
  margin: 3px 8px;
}
.filter-bar__filters .react-daterange-picker__wrapper {
  height: 40px;
}
.filter-bar__status-filters {
  margin: 8px 0;
}
.filter-bar__status-filters label {
  color: var(--dark-grey-004);
}