.error-validation {
  height: 22px;
  line-height: 18px;
  margin-bottom: 22px;
}
.error-validation--color { 
  color: var(--red-001);
}

#login .error-validation {
  margin-bottom: 0px;
}

#login .error-validation.error-validation-password {
  margin-bottom: 45px;
}
